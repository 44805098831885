<!--
 * @Author: chenxing
 * @Date: 2021-06-24 15:06:46
 * @LastEditors: chenxing
 * @LastEditTime: 2022-01-11 18:09:40
-->
<template>
  <div class="upload-container" v-bind="$attrs">
    <div class="header">
      <div class="btn-container">
        <a-button :loading="uploadingAll" type="primary" style="padding: 0 0.5em">
          <CloudUploadOutlined />
          上传文件
          <input type="file" style="opacity: 0; position: absolute; top: 0; left: 0; width: 100%; height: 100%" :accept="filetype" ref="fileInput1" multiple @change="onInputChange" />
        </a-button>
        <!-- <a-button type="primary" :loading="uploadingAll" :disabled="fileList.length == 0" @click="onUploadAllBtnClick">
              <UploadOutlined v-show="!uploadingAll" />
              {{ uploadingAll ? '正在上传' : '开始上传' }}
            </a-button> -->
      </div>
      <div class="progress-container">
        <!-- <a-progress type="circle" :width="30" :percent="uploadAllProgress" /> -->
        <!-- <label>
          成功<label style="color: #197afb">{{ uploadCount }} </label> / 失败<label style="color: #ff3737">{{ failedCount }}</label> / 总计{{ fileList.length }}
        </label>   -->
        <span>
          上传进度：
          <label style="color: #197afb">{{ uploadCount }}</label>
          <label style="margin-right: 20px">/{{ fileList.length }}</label>
          失败：
          <label style="color: #ff3737">{{ failedCount }}</label>
        </span>
      </div>
    </div>
    <div class="file-list file-list-list-card">
      <a-empty v-if="fileList.length == 0" :image="simpleImage" style="margin: auto">
        <template #description> 拖拽文件到此处上传 </template>
      </a-empty>
      <div class="file-preview-list-card" v-for="(file, index) in fileList" :key="file.path" @mousemove="onThumbMouseMove(index)" @mouseenter="onThumbMouseEnter(index)" @mouseleave="onThumbMouseLeave(index)">
        <div class="bg-progress-bar" :style="{ width: file.uploadProgress + '%' }"></div>

        <a-spin :spinning="file.processing" style="height: 1rem; width: 1rem; display: flex; align-items: center; justify-content: center">
          <div v-if="file.localUrl" class="img-container" :class="{ 'img-container-showbg': file.previewIconShow }" @click="onPreview(file)">
            <EyeOutlined />
            <div class="preview-mask"></div>
            <img v-show="file.thumbUrl" :src="file.thumbUrl" alt="缩略图" />
          </div>
        </a-spin>
        <a-tooltip :title="file.name">
          <div style="display: flex; width: 10em; flex: 1; font-size: 0.375rem">
            <label class="file-name textHide">{{ file.name }}</label>
            <label v-show="file.uploaded" style="min-width: 60px; margin-left: 10px">上传成功</label>
            <label v-if="file.errMsg" class="file-error-message">{{ file.errMsg }}</label>
          </div>
        </a-tooltip>
        <div class="file-op">
          <CloseCircleOutlined style="color: rgba(129, 129, 129, 1)" v-show="!file.processing" @click="onRemoveFileBtnClick($event, index)" />
          <LoadingOutlined v-show="file.uploading" />
          <!-- <CloudUploadOutlined v-show="!file.uploaded && !file.uploading && !file.processing" @click="onUploadBtnClick(index)" /> -->
          <PauseOutlined v-show="file.uploading" @click="onPauseBtnClick(index)" />
          <ReloadOutlined v-show="!file.uploaded && !file.uploading && !file.processing && !file.exist" @click="onResumeUploadBtnClick(index)" />
          <!-- <a-progress v-show="file.uploading || file.uploaded" type="circle" :class="{ 'progress-uploaded': file.uploaded }" :percent="file.uploadProgress" :width="30" />
           -->
        </div>
      </div>
    </div>
  </div>

  <a-modal :visible="showPreview" :title="playerOptions.title" :width="previewModalWidth" :body-style="{ padding: 0, textAlign: 'center' }" :closable="false" :mask-closable="false">
    <img v-show="imgPreviewUrl && !playerOptions.src" :src="imgPreviewUrl" :style="{ width: imgPreviewWidth, height: imgPreviewHeight }" />
    <video-player v-if="playerOptions.src" v-bind="playerOptions"></video-player>
    <template #footer>
      <a-button type="default" @click="onClosePreviewBtnClick">关闭</a-button>
    </template>
  </a-modal>

  <div v-show="showdragzone" class="drag-zone" @drop="onDragZoneDragLeaveOrEnd" @dragleave="onDragZoneDragLeaveOrEnd">
    <CloudUploadOutlined class="icon-upload" />
    <label class="tip">拖拽文件到此处</label>
    <input type="file" class="file-input" :accept="filetype" ref="fileInput" multiple @change="onInputChange" />
  </div>
</template>

<script>
import { multipartUpload, resumeUpload, delObjects, abortUpload } from '@/utils/oss.js';
import FileMD5 from '@/utils/md5.js';
import videoPlayer from '@/components/Video/videoPlayer';
import { CloudUploadOutlined, CloseCircleOutlined, EyeOutlined, LoadingOutlined, PauseOutlined, ReloadOutlined } from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import { checkMD5,videoFileToAgent } from '@/api/material/material';

export default {
  components: {
    videoPlayer,
    CloudUploadOutlined,
    CloseCircleOutlined,
    EyeOutlined,
    LoadingOutlined,
    PauseOutlined,
    ReloadOutlined,
  },
  props: {
    upLoadPrefix: {
      type: String,
      default: '',
    },
    maxcount: {
      type: Number,
      default: 50,
    },
    filetype: {
      type: String,
      default: 'image/*,video/*',
    },
    showdragzone: {
      type: Boolean,
      default: false,
    },
    clearblob: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['uploadComplete', 'allUpload', 'removeFile', 'update:showdragzone', 'update:clearblob'],
  data() {
    return {
      userInfo: this.$store.state.oauth.userInfo,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      fileList: [],
      downloadUrl: '',
      videoPreviewUrl: '',
      imgPreviewUrl: '',
      playerOptions: {},
      showPreview: false,
      uploadingAll: false,
      uploadCount: 0, //上传成功的数量
      failedCount: 0, //上传失败的数量
      uploadResult: [],
      imgPreviewHeight: 'auto',
      imgPreviewWidth: '520px',
      previewModalWidth: 520,
      previewModalMaxWidth: document.documentElement.clientWidth * 0.6,
    };
  },
  computed: {
    uploadObjectDatePrefix() {
      let date = new Date();
      var mm = date.getMonth() + 1;
      var dd = date.getDate();

      return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('/');
    },
    uploadAllProgress() {
      return Math.round((this.uploadCount / this.fileList.length) * 100);
    },
  },
  watch: {
    clearblob(newVal) {
      if (newVal == true) {
        // 清理内存中对文件的引用，避免内存泄漏
        this.fileList.forEach(file => {
          URL.revokeObjectURL(file.localUrl);
        });
        this.fileList = [];
        this.uploadResult = [];
        this.uploadingAll = false;
        this.uploadCount = 0;
        this.failedCount = 0;
        this.$refs.fileInput.value = '';
        this.$refs.fileInput1.value = '';
        this.$emit('allUpload', false);
        this.$emit('update:clearblob', false);
      }
    },
    uploadCount(newVal, oldVal) {
      if (newVal === this.fileList.length && oldVal < newVal) {
        this.uploadingAll = false;
        this.$message.success('上传完成');
        this.$emit('allUpload', true);
      }
    },
  },
  methods: {
    onDragZoneDragLeaveOrEnd(e) {
      this.$emit('update:showdragzone', false);
    },
    //file input改变时的事件
    onInputChange(event) {
      this.$emit('allUpload', false);
      let fs = Array.from(event.target.files);

      fs.forEach((file, index) => {
        // 根据传入的filetype判断file的类型是否符合要求，防止用户自行选择所有文件类型并上传不符合要求的文件
        if (!file.type || this.filetype.search(file.type.split('/')[0]) === -1) {
          this.$message.error('不支持的文件类型-' + file.name);
          return Promise.reject('wrong file type');
        }

        if (this.fileList.length == this.maxcount) {
          if (!this.hasShownWarning) {
            this.hasShownWarning = true;
            this.$message.warn(`单次最大只能上传${this.maxcount}个文件`, () => {
              this.hasShownWarning = false;
            });
          }
          return;
        }

        file.processing = true;
        // 先加载到列表，再处理
        this.fileList.push(file);
        this.processFile(file).then(
          data => {
            file.processing = false;
            this.fileList = [].concat(this.fileList);
          },
          err => {
            file.processing = false;
            this.fileList = [].concat(this.fileList);
          }
        );
      });
    },
    // 对添加的文件进行一些处理
    async processFile(file) {
      let _this = this;

      // 计算文件的md5
      let md5 = await new Promise((resolve, reject) => {
        FileMD5.md5(file, (err, md5) => {
          if (err) {
            _this.$message.error(err);
            reject(err);
          } else {
            resolve(md5);
          }
        });
      });

      if (_this.fileList.some(file => file.md5 == md5)) {
        _this.$message.warn('重复文件-' + file.name);
        _this.fileList.splice(_this.fileList.indexOf(file), 1);
        return Promise.reject('重复文件-' + file.name);
      }

      let objectUrl = URL.createObjectURL(file);
      let rootDir = '';
      // 如果是图片，需要计算宽度方便预览时候设置预览窗口大小
      if (/^image\//.test(file.type)) {
        rootDir = 'images';
        file.localUrl = objectUrl;
        file.viewport = await new Promise(resolve => {
          let img = new Image();
          img.src = objectUrl;
          img.onload = function () {
            const canvas = document.createElement('canvas');
            if (img.width > img.height) {
              canvas.width = 200;
              canvas.height = img.height / (img.width / 200);
            } else {
              canvas.width = img.width / (img.height / 200);
              canvas.height = 200;
            }
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

            file.thumbUrl = canvas.toDataURL('image/png');
            resolve({ width: img.width, height: img.height });
          };
        });
      }
      // 如果是视频，要读取第一帧作为缩略图
      else if (/^video\//.test(file.type)) {
        rootDir = 'videos';
        file.localUrl = objectUrl;
        file.viewport = await new Promise(resolve => {
          let video = document.createElement('video');
          video.src = objectUrl;
          video.onloadeddata = () => {
            // 获取第一帧作为缩略图
            file.thumbUrl = this.getFirstFrame(video, file);
            resolve({ width: video.videoWidth, height: video.videoHeight });
          };
          video.play();
        });
      } else {
        _this.$message.error('不支持的文件类型');
        return Promise.reject('wrong file type');
      }
      let lastIndex = file.name.lastIndexOf('.');
      let temp = [];
      temp.push(file.name.substring(0, lastIndex));
      temp.push(new Date().getTime());
      temp.push(file.name.substring(lastIndex + 1));
      let stampFileName = `${temp[0]}-${temp[1]}.${temp[2]}`;
      file.uploadObjectName = `${_this.upLoadPrefix}/${rootDir}/${_this.uploadObjectDatePrefix}/${stampFileName}`;
      file.uploadThumbName = file.uploadObjectName + '-thumb.png';
      file.md5 = md5;
      //校验MD5在数据库是否存在
      this.checkmd5([file]).then(checkResult => {
        if (checkResult.list.length > 0) {
          this.uploadResult.push(checkResult.list[0]);
          this.uploadCount += 1;
          this.$emit('uploadComplete', this.uploadResult);
        } else {
          this.uploadFile(file).then(result => {
            if (result) this.uploadResult.push(result);
            this.$emit('uploadComplete', this.uploadResult);
          });
        }
      });

      return Promise.resolve('success');
    },
    // 获取视频第一帧图像
    getFirstFrame(video) {
      const canvas = document.createElement('canvas');
      if (video.videoWidth > video.videoHeight) {
        canvas.width = 200;
        canvas.height = video.videoHeight / (video.videoWidth / 200);
      } else {
        canvas.width = video.videoWidth / (video.videoHeight / 200);
        canvas.height = 200;
      }
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, canvas.width, canvas.height);
      video.pause();
      return canvas.toDataURL('image/png');
    },
    onThumbMouseMove(index) {
      if (!this.fileList[index].previewIconShow) {
        this.onThumbMouseEnter(index);
      }
    },
    onThumbMouseEnter(index) {
      this.fileList[index].previewIconShow = true;
      this.fileList = [].concat(this.fileList);
    },
    onThumbMouseLeave(index) {
      this.fileList[index].previewIconShow = false;
      this.fileList = [].concat(this.fileList);
    },
    // 预览事件
    onPreview(file) {
      this.showPreview = true;
      if (/^image\//.test(file.type)) {
        this.previewModalWidth = Math.min(file.viewport.width, this.previewModalMaxWidth);
        // 需要根据图片的宽高比设置预览窗口的最大高度或者最大宽度
        if (file.viewport.height > file.viewport.width) {
          this.imgPreviewWidth = 'auto';
          this.imgPreviewHeight = '520px';
        } else {
          this.imgPreviewWidth = this.previewModalWidth + 'px';
          this.imgPreviewHeight = 'auto';
        }

        this.imgPreviewUrl = file.localUrl;
        this.playerOptions.title = file.name;
      } else {
        this.$nextTick(() => {
          this.previewModalWidth = Math.min(file.viewport.width, this.previewModalMaxWidth);
          this.playerOptions = { src: file.localUrl, type: file.type, title: file.name, width: this.previewModalWidth };
        });
      }
    },
    onClosePreviewBtnClick() {
      this.showPreview = false;
      this.playerOptions.src = '';
    },
    // 上传文件
    onUploadAllBtnClick() {
      this.uploadCount = 0;
      this.failedCount = 0;
      this.uploadingAll = true;
      //校验文件是否已经上传过
      this.checkmd5(this.fileList).then(checkResult => {
        if (checkResult.list.length > 0) {
          this.uploadResult.push(checkResult.list);
        }
        //筛选出没有上传过的文件进行处理
        this.fileList
          .filter(file => {
            return !checkResult.list.some(cf => cf.md5 == file.md5);
          })
          .forEach(file => {
            this.uploadFile(file);
          });
      });
    },
    async uploadFile(file) {
      //如果已经上传过，直接返回
      if (file.uploaded) {
        // this.$nextTick(() => (this.uploadCount += 1));
        return;
      }
      /*
       ** multipartUpload
       ** @param1 包含路径的文件名称
       ** @param2 文件对象File或者Blob对象
       ** @param3 上传文件的meta信息（可选）
       ** @param4 上传进度回调
       */
      file.uploading = true;
      this.fileList = [].concat(this.fileList);
      //上传文件本体
      let result = await multipartUpload(file.uploadObjectName, file, { fileName: encodeURIComponent(file.name) }, (progress, checkPoint, ossInstance) => {
        file.uploadProgress = Math.round(progress * 100);
        file.checkPoint = checkPoint;
        file.ossInstance = ossInstance;
        this.fileList = [].concat(this.fileList);
      });

      file.uploading = false;
      file.checkPoint = undefined;
      if (result.message) {
        if (result.message == '暂停上传') this.failedCount += 1;
        this.$message.error(`${file.name} - ${result.message}`);
        file.errMsg = result.message;
        this.fileList = [].concat(this.fileList);
        return;
      }
      //上传缩略图
      let thumbResult = await multipartUpload(file.uploadThumbName, this.dataURItoBlob(file.thumbUrl), { fileName: encodeURIComponent(file.name) });
      file.uploaded = true;
      this.fileList = [].concat(this.fileList);

      result.fileName = file.name;
      result.md5 = file.md5;
      result.thumb = thumbResult.name;
      result.size = file.size;
      result.width = file.viewport.width;
      result.height = file.viewport.height;
      result.url = result.name;
      result.file = file;

      this.uploadResult.push(result);
      this.uploadCount += 1;

      // let videoToAgentResult = await videoFileToAgent(file.name,file.md5,file);
      // console.error(videoToAgentResult)
      return;
    },
    async checkmd5(fs) {
      let param = fs.map(f => f.md5);
      let checkResult = await checkMD5(param); //请求接口

      if (checkResult.code === 0) {
        checkResult.list.forEach(item => {
          let index = fs.findIndex(f => {
            return f.md5 == item.md5;
          });
          // fs[index].uploaded = true;
          // fs[index].uploadProgress = 100;
          fs[index].etag = item.etag;
          fs[index].url = item.url;
          fs[index].thumb = item.thumb;
          item.exist = fs[index].exist = true;
          fs[index].errMsg = '存在相同素材文件，id：' + item.id;
        });
        this.fileList = [].concat(this.fileList);
      }
      return checkResult;
    },
    onResumeUploadBtnClick(index) {
      let file = this.fileList[index];
      resumeUpload(
        file.uploadObjectName,
        file,
        { fileName: encodeURIComponent(file.name) },
        (progress, checkPoint, ossInstance) => {
          file.uploadProgress = Math.round(progress * 100);
          file.checkPoint = checkPoint;
          file.ossInstance = ossInstance;
          this.fileList = [].concat(this.fileList);
        },
        file.ossInstance,
        file.checkPoint
      ).then(result => {
        if (result.message) {
          if (result.message == '暂停上传') this.failedCount += 1;
          this.$message.error(`${file.name} - ${result.message}`);
          file.errMsg = result.message;
          this.fileList = [].concat(this.fileList);
        }
      });
      file.uploading = true;
      file.errMsg = '';
      this.fileList = [].concat(this.fileList);
      this.failedCount -= 1;
    },
    onUploadBtnClick(index) {
      let file = this.fileList[index];
      //校验MD5在数据库是否存在
      this.checkmd5([file]).then(checkResult => {
        if (checkResult.list.length > 0) {
          this.uploadResult.push(checkResult.list[0]);
          this.uploadCount += 1;
          this.$emit('uploadComplete', this.uploadResult);
        } else {
          this.uploadFile(file).then(result => {
            if (result) this.uploadResult.push(result);
            this.$emit('uploadComplete', this.uploadResult);
          });
        }
      });
    },
    onRemoveFileBtnClick(e, index) {
      e.preventDefault();
      let file = this.fileList[index];
      //如果正在处理缩略图，禁止删除
      if (file.processing) return;
      //如果已经上传，删除已经上传的文件
      if (file.uploaded == true) {
        this.uploadCount -= 1;
        delObjects([file.uploadObjectName, file.uploadThumbName], true);
      }
      let md5 = file.md5;

      if (file.uploading) {
        //如果正在上传，终止上传，否则终止处理
        let checkpoint = file.checkPoint;
        if (checkpoint) abortUpload(checkpoint);
      }
      //如果是暂停状态，需要把异常数量减1
      if (file.errMsg) {
        this.failedCount -= 1;
      }
      this.fileList.splice(index, 1);
      this.uploadResult = this.uploadResult.filter(rf => rf.md5 != md5);

      if (this.fileList.length === 0) {
        this.$refs.fileInput.value = '';
        this.$refs.fileInput1.value = '';
        this.uploadingAll = false;
      }

      if (this.uploadCount === this.fileList.length) {
        this.$emit('allUpload', true);
      }

      this.$emit('removeFile', md5);
    },
    onPauseBtnClick(index) {
      this.fileList[index].uploading = false;
      this.fileList[index].ossInstance.cancel();
      this.fileList = [].concat(this.fileList);
    },
    //base64转成blob对象
    dataURItoBlob(dataURI) {
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // mime类型
      var byteString = atob(dataURI.split(',')[1]); //base64 解码
      var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
      var intArray = new Uint8Array(arrayBuffer); //创建视图

      for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      return new Blob([intArray], { type: mimeString });
    },
    //blob二进制 to base64
    blobToDataURI(blob, callback) {
      var reader = new FileReader();
      reader.onload = function (e) {
        callback(e.target.result);
      };
      reader.readAsDataURL(blob);
    },
  },
};
</script>

<style lang="less" scoped>
.bg-progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 1.625rem;
  background-size: 1.625rem 1.625rem;
  background-color: #bbd8ff;
  background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  transition: all ease 200ms;
}

.upload-container {
  .header {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.2em;
    left: 0;
    width: 100%;

    .btn-container {
      margin-left: 8em;
    }

    .progress-container {
      position: absolute;
      right: 1em;
    }
  }

  .file-list {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 7.8125rem;
    min-height: 7.8125rem;
    border: 1px solid #f0f0f0;
    padding: 0.375rem;
    margin: 0.47rem 0;
    background: #f0f3f9;
    border-radius: 0.125rem;

    .file-preview-list-card {
      display: flex;
      width: 100%;
      height: 1.625rem;
      padding: 0.1875rem 0.25rem;
      text-align: left;
      border-radius: 0.125rem;
      background-color: #fff;
      margin: 0.2em 0;
      align-items: center;
      position: relative;
      z-index: 0;

      * {
        display: block;
        margin: auto;
      }

      .file-name {
        margin: 0.5em 0 0.5em 1em;
        flex: 1;
      }

      .file-error-message {
        color: #ff3737;
        white-space: nowrap;
        margin-left: 1em;
        flex: 0;
      }

      .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 1.25rem;
        width: 2.25rem;
        flex: 0;

        img {
          height: inherit;
          width: inherit;
          object-fit: cover;
        }

        .anticon-eye {
          transition: opacity ease 300ms;
          opacity: 0;
          z-index: 3;
          position: absolute;
          margin: auto;
          font-size: 2em;
        }

        .preview-mask {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(235, 235, 235, 0.7);
          opacity: 0;
          transition: opacity ease 300ms;
          z-index: 2;
        }
      }

      .img-container-showbg {
        .anticon-eye,
        .preview-mask {
          opacity: 1;
        }
      }

      .file-op {
        flex: 0;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 0 1em;
        height: 30px;

        :deep(.ant-progress) {
          flex: 1;
          text-align: left;
          .ant-progress-inner {
            transition: all ease-out 500ms 300ms;
          }
        }

        .progress-uploaded {
          :deep(.ant-progress-inner) {
            transform: scale(0.5);
          }
        }

        .anticon {
          flex: 0;
          margin: 0.3em;
        }
      }
    }
  }

  .file-list-list-card {
    flex-wrap: unset;
    flex-direction: column;
  }
}

.drag-zone {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  left: 0;
  top: 0;
  border: dashed 2px #ccc;

  .icon-upload {
    font-size: 1.6rem;
    display: block;
  }

  .tip {
    display: block;
  }

  .file-input {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
</style>