<template>
  <a-modal :visible="visible" class="materialHandleModal" :ok-button-props="{ loading: btnLoading }" @ok="handleOK" @cancel="handleCancel" :width="600" :mask-closable="false">
    <template #title>
      <span class="material_modal_title">{{ modalTitle }}</span>
      <EditTwoTone class="material_title_edit ml10" @click="editMaterialTitle" />
    </template>
    <div style="position: relative" @dragenter="showDragZone = true">
      <a-form ref="materialModal" :rules="materialModalRules" :model="materialModalData">
        <a-row v-if="(operationMode === 'update' && materialModalData.userId === userId) || operationMode != 'edit'">
          <a-col :span="24">
            <a-form-item label="素材文件" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }" name="materialList">
              <input type="text" style="opacity: 0" disabled />
            </a-form-item>
          </a-col>
        </a-row>
        <upload
          v-if="operationMode != 'edit'"
          class="custom_upload"
          :filetype="uploadFileType"
          v-model:showdragzone="showDragZone"
          v-model:clearblob="clearBlob"
          :maxcount="parseInt(`${operationMode === 'add' ? 50 : 1}`)"
          @upload-complete="uploadComplete"
          @remove-file="removeFile"
          @all-upload="
            val => {
              allUploaded = val;
            }
          "
        />
        <a-row v-if="type === 'IMAGE'" :gutter="10">
          <!-- 素材类型 -->
          <a-col :span="12">
            <a-form-item label="素材类型" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="materialChannel">
              <!--  -->
              <a-select v-model:value="materialModalData.materialChannel" :options="materialChannelOptions" placeholder="请选择类型" />
            </a-form-item>
          </a-col>
          <!-- 关联产品 -->
          <a-col v-if="materialModalData.materialChannel !== 'QJJ'" :span="12">
            <a-form-item label="关联产品" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="ofProjectCode">
              <a-select v-model:value="materialModalData.ofProjectCode" placeholder="请选择产品" v-bind="$filterOptions" @change="getProfitList(materialModalData.ofProjectCode)">
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option v-for="option in projectListOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- 利益点 -->
          <a-col :span="12" v-if="materialModalData.ofProjectCode">
            <a-form-item label="利益点" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <SelectMultiple v-model:value="materialModalData.label" :select-data="materialModalData.label" :select-options="profitList" :label-in-value="true" placeholder="请选择利益点" v-bind="$multipleFilterOptions" select-option-value="name" select-option-label="name" />
            </a-form-item>
          </a-col>
          <!-- 使用部门 -->
          <a-col :span="12">
            <a-form-item name="useDeptId" required label="使用部门" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select v-model:value="materialModalData.useDeptId" placeholder="请选择使用部门" show-search :filter-option="true" option-filter-prop="label" v-bind="$filterOptions" allow-clear>
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option v-for="option in filterDepIdList" :key="String(option.deptId)" :value="String(option.deptId)" :label="option.name">{{ option.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- 使用渠道 -->
          <a-col :span="12">
            <a-form-item name="channel" required label="使用渠道" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select v-model:value="materialModalData.channel" placeholder="请选择使用渠道" mode="multiple">
                <a-select-option value="TT">巨量引擎</a-select-option>
                <a-select-option value="GDT">腾讯广告</a-select-option>
                <a-select-option value="TTSTAR">巨量星图</a-select-option>
                <a-select-option value="KS">磁力引擎</a-select-option>
                <a-select-option value="QC">巨量千川</a-select-option>
                <a-select-option value="AQY">爱奇艺</a-select-option>
                <a-select-option value="Bilibili">Bilibili</a-select-option>
                <a-select-option value="ZFB">支付宝</a-select-option>

                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- 平均成本 -->
          <a-col v-if="materialModalData.materialChannel == 'WBCG'" :span="12">
            <a-form-item label="成本" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="makeCost">
              <a-input v-model:value="materialModalData.makeCost" placeholder="请输入素材成本" />
            </a-form-item>
          </a-col>
          <!-- 上传人员 -->
          <a-col v-if="operationMode === 'edit'" :span="12">
            <a-form-item label="上传人员" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="userId">
              <a-select v-model:value="materialModalData.userId" placeholder="请选择上传人员" v-bind="$filterOptions">
                <a-select-option v-for="option in uploadPersonList" :key="option.userId" :value="option.userId" :label="option.userCode">{{ option.userCode }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- 备注 -->
          <a-col :span="24">
            <a-form-item label="备注" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-textarea v-model:value="materialModalData.remarkInfo" placeholder="请输入备注" :rows="4" :maxlength="200" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-else :gutter="10">
          <!-- 是否保护 -->
          <a-col :span="12" v-if="operationMode != 'edit'">
            <a-form-item label="素材保护" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="protected">
              <a-select v-model:value="materialProtected">
                <a-select-option value="Y">启用</a-select-option>
                <a-select-option value="N">不启用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="materialProtected=== 'Y' && operationMode != 'edit'">
            <a-form-item label="保护端口" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="protectedPort">
              <a-select v-model:value="protectedPort">
                <a-select-option value="110761960254">芦鸣</a-select-option>
                <a-select-option value="1754527817162760">洽尔</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="素材类型" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="materialChannel">
              <a-select v-model:value="materialModalData.materialChannel" placeholder="请选择类型" @change="changeChannel">
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <!-- <template v-if="userInfoJobs === 'POSTER' || userInfoJobs === 'ADMIN'"> -->
                <a-select-opt-group label="内部">
                  <a-select-option key="QJJ" value="QJJ">情景</a-select-option>
                  <a-select-option key="QJJ_KB" value="QJJ_KB">情景-口播</a-select-option>
                  <a-select-option key="QJJ_ZH" value="QJJ_ZH">情景-置换</a-select-option>
                  <a-select-option key="GM_QJJ_AE" value="GM_QJJ_AE">游戏情景AE</a-select-option>
                  <a-select-option key="GM_QT_AE" value="GM_QT_AE">游戏前贴AE</a-select-option>
<!--                  <a-select-option key="GJSC" value="GJSC">共建素材</a-select-option>-->
<!--                  <a-select-option key="GJ_TP_10" value="GJ_TP_10">共建-套皮10分钟</a-select-option>-->
                  <a-select-option key="YSJJ" value="YSJJ">剪辑</a-select-option>
                  <a-select-option key="YSJJ_2X" value="YSJJ_2X">剪辑*2</a-select-option>
                  <a-select-option key="YSJJ_3X" value="YSJJ_3X">剪辑*3</a-select-option>
                  <a-select-option key="YSJJ_4X" value="YSJJ_4X">剪辑*4</a-select-option>
                  <a-select-option key="YSJJ_7X" value="YSJJ_7X">剪辑*7</a-select-option>
<!--                  <a-select-option key="EC" value="EC">二创剪辑</a-select-option>-->
                  <a-select-option key="HJ" value="HJ">剪辑-混剪</a-select-option>
                  <a-select-option key="AIJJ" value="AIJJ">剪辑-Ai</a-select-option>
<!--                  <a-select-option key="AIJJ_KG" value="AIJJ_KG">AI剪辑(客供)</a-select-option>-->
                  <a-select-option key="TP_QJJ" value="TP_QJJ">情景-1/2</a-select-option>
<!--                  <a-select-option key="TP_HQ_PY" value="TP_HQ_PY">套皮-后期-配音</a-select-option>-->
                  <a-select-option key="TP_HQ_JZ" value="TP_HQ_JZ">情景-混剪</a-select-option>
<!--                  <a-select-option key="TP_HQ_QT" value="TP_HQ_QT">套皮-后期-前贴</a-select-option>-->
<!--                  <a-select-option key="TP_HQ_5" value="TP_HQ_5">套皮-后期-5分钟</a-select-option>-->
                  <a-select-option key="TP_HQ_15" value="TP_HQ_15">剪辑-1/5</a-select-option>
                  <a-select-option key="TP_HQ_30" value="TP_HQ_30">剪辑-1/2</a-select-option>
                  <a-select-option key="TP_EC" value="TP_EC">情景-二改</a-select-option>
                  <a-select-option key="TP_EC_WC" value="TP_EC_WC">情景-二改-外采</a-select-option>
                  <a-select-option key="TP_HJ_WC" value="TP_HJ_WC">情景-混剪-外采</a-select-option>
                  <a-select-option key="TP_EC_WB" value="TP_EC_WB">情景-二改-外部</a-select-option>
                  <a-select-option key="TP_HJ_WB" value="TP_HJ_WB">情景-混剪-外部</a-select-option>

                  <!-- <a-select-option key="ECQJJ" value="ECQJJ">二创情景剧</a-select-option> -->
                </a-select-opt-group>
                <a-select-opt-group label="外部">
                  <a-select-option key="CG" value="CG">采购</a-select-option>
                  <a-select-option key="KHTG" value="KHTG">客户提供</a-select-option>
                </a-select-opt-group>
                <!-- </template> -->
                <!-- <template v-else>
                  <a-select-option key="CG" value="CG">采购</a-select-option>
                  <a-select-option key="KHTG" value="KHTG">客户提供</a-select-option>
                </template> -->
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="materialModalData.materialChannel == 'YSJJ'" :span="12">
            <a-form-item class="inputCost" label="平均成本" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input-number style="width: 100%" :min="0" v-model:value="materialModalData.makeCost" placeholder="请输入素材平均成本" />
            </a-form-item>
          </a-col>
          <a-col v-if="materialModalData.materialChannel !== 'QJJ'" :span="12">
            <a-form-item label="关联产品" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="ofProjectCode">
              <a-select v-model:value="materialModalData.ofProjectCode" placeholder="请选择产品" v-bind="$filterOptions" @select="selectYsjjProduct" allow-clear @change="changeProduct">
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option v-for="option in projectListOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="materialModalData.materialChannel == 'QJJ'" :span="12">
            <a-form-item label="关联产品" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" required name="ofProjectCode">
              <a-select v-model:value="materialModalData.ofProjectCode" placeholder="请选择产品" v-bind="$filterOptions" @select="selectYsjjProduct" allow-clear @change="changeProduct">
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option v-for="option in projectListOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- 利益点 -->
          <a-col :span="12" v-if="materialModalData.ofProjectCode">
            <a-form-item label="利益点" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <SelectMultiple v-model:value="materialModalData.label" :select-data="materialModalData.label" :select-options="profitList" :label-in-value="true" placeholder="请选择利益点" v-bind="$multipleFilterOptions" select-option-value="name" select-option-label="name" />
            </a-form-item>
          </a-col>
          <!-- 使用部门 -->
          <a-col :span="12">
            <a-form-item name="useDeptId" required label="使用部门" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select v-model:value="materialModalData.useDeptId" placeholder="请选择使用部门" show-search :filter-option="true" option-filter-prop="label" v-bind="$filterOptions" allow-clear>
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option v-for="option in filterDepIdList" :key="String(option.deptId)" :value="String(option.deptId)" :label="option.name">{{ option.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- 使用渠道 -->
          <a-col :span="12">
            <a-form-item name="channel" required label="使用渠道" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select v-model:value="materialModalData.channel" placeholder="请选择使用渠道" mode="multiple">
                <a-select-option value="TT">巨量引擎</a-select-option>
                <a-select-option value="GDT">腾讯广告</a-select-option>
                <a-select-option value="TTSTAR">巨量星图</a-select-option>
                <a-select-option value="KS">磁力引擎</a-select-option>
                <a-select-option value="QC">巨量千川</a-select-option>
                <a-select-option value="AQY">爱奇艺</a-select-option>
                <a-select-option value="Bilibili">Bilibili</a-select-option>
                <a-select-option value="ZFB">支付宝</a-select-option>

                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="materialModalData.materialChannel === 'QJJ_ZH' || materialModalData.materialChannel === 'QJJ' || materialModalData.materialChannel === 'GM_QJJ_AE' || materialModalData.materialChannel === 'QJJ_KB' || materialModalData.materialChannel === 'YSJJ' || materialModalData.materialChannel === 'TP_QJJ' || materialModalData.materialChannel === 'TP_HQ_QT' || materialModalData.materialChannel === 'TP_HQ_15' || materialModalData.materialChannel === 'TP_HQ_30' || materialModalData.materialChannel === 'TP_EC'">
            <!-- 情景剧关联脚本(必填) -->
            <a-form-item v-if="materialModalData.materialChannel === 'QJJ_ZH'  ||  materialModalData.materialChannel === 'QJJ'  ||  materialModalData.materialChannel === 'GM_QJJ_AE' || materialModalData.materialChannel === 'QJJ_KB' || materialModalData.materialChannel === 'TP_QJJ' || materialModalData.materialChannel === 'TP_EC'" style="margin-bottom: 0 !important" label="关联脚本" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" required name="makeCode">
              <div style="display: flex">
                <a-select style="width: 85%" v-model:value="materialModalData.makeCode" placeholder="请选择脚本" v-bind="$filterOptions" dropdown-class-name="select-options" allow-clear @select="selectConnectionScript">
                  <template #notFoundContent>
                    <a-empty :image="simpleImage">
                      <template #description>
                        <span>暂无数据</span>
                      </template>
                    </a-empty>
                  </template>
                  <a-select-option v-for="option in getScriptByTiProduct" :key="option.makeCode" :value="option.makeCode" :label="option.makeName">{{ option.makeName }}</a-select-option>
                </a-select>
                <a-button style="width: 15%" @click="openMakeCodeChange">
                  <template #icon><SearchOutlined /></template>
                </a-button>
              </div>
            </a-form-item>
            <!-- 剪辑的关联脚本(非必填) -->
            <a-form-item v-if="materialModalData.materialChannel === 'YSJJ' || materialModalData.materialChannel === 'TP_HQ_QT' || materialModalData.materialChannel === 'TP_HQ_15' || materialModalData.materialChannel === 'TP_HQ_30'" style="margin-bottom: 0 !important" label="关联脚本" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <div style="display: flex">
                <a-select style="width: 85%" v-model:value="materialModalData.makeCode" placeholder="请选择脚本" v-bind="$filterOptions" dropdown-class-name="select-options" allow-clear @select="selectConnectionScript">
                  <template #notFoundContent>
                    <a-empty :image="simpleImage">
                      <template #description>
                        <span>暂无数据</span>
                      </template>
                    </a-empty>
                  </template>
                  <a-select-option v-for="option in getScriptByTiProduct" :key="option.makeCode" :value="option.makeCode" :label="option.makeName">{{ option.makeName }}</a-select-option>
                </a-select>
                <a-button style="width: 15%" @click="openMakeCodeChange">
                  <template #icon><SearchOutlined /></template>
                </a-button>
              </div>
            </a-form-item>
            <!-- 编辑关联脚本 -->
            <a class="editScript" style="padding-left: 25%" v-if="(materialModalData.materialChannel === 'QJJ' || materialModalData.materialChannel === 'TP_QJJ' || materialModalData.materialChannel === 'TP_EC' && materialModalData.makeCode) || (materialModalData.materialChannel === 'YSJJ' && materialModalData.makeCode)" :span="12" @click="onEditScriptClick">编辑脚本>></a>
          </a-col>

          <!-- 情景类别 -->
          <a-col v-if=" materialModalData.materialChannel == 'QJJ' ||  materialModalData.materialChannel === 'GM_QJJ_AE' || materialModalData.materialChannel === 'QJJ_KB' " :span="12" >
            <a-form-item label="情景类别" required :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"> <!--  -->
              <a-select  v-model:value="materialModalData.sitcomType" placeholder="请选择情景类别" v-bind="$filterOptions" allow-clear>
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option   v-for="option in sitcomTypeList" :key="option.label" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="materialModalData.materialChannel == 'TP_HQ_PY'" :span="12">
            <a-form-item label="制片" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select v-model:value="materialModalData.producerId" placeholder="请选择制片" v-bind="$filterOptions" allow-clear>
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option v-for="option in filmmakerOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="!materialModalData.makeCode" :span="12">
            <a-form-item label="摄影" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" >
              <a-select mode="multiple"  v-model:value="materialModalData.shootingIds" placeholder="请选择摄影" v-bind="$filterOptions" allow-clear>
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option v-for="option in phtographerHJOptions" :key="String(option.userId)" :value="String(option.userId)" :label="option.userCode">{{ option.userCode }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if=" !materialModalData.makeCode " :span="12">
            <a-form-item label="编导" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select mode="multiple" v-model:value="materialModalData.screenwriterIds" placeholder="请选择编导" v-bind="$filterOptions" allow-clear>
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option v-for="option in getAllDirectorList" :key="option.userId" :value="option.userId" :label="option.userCode">{{ option.userCode }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>


          <!--关联小组-->
          <a-col v-if="materialModalData.materialChannel == 'GJSC' || materialModalData.materialChannel == 'GJ_TP_10'" :span="12" >
            <a-form-item label="关联小组" required :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select mode="multiple" v-model:value="materialModalData.groupNames" placeholder="请选择小组" v-bind="$filterOptions" allow-clear>
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option   v-for="option in groupNameList" :key="option.key" :value="option.name" :label="option.name">{{ option.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="(materialModalData.materialChannel == 'YSJJ' || materialModalData.materialChannel == 'QJJ'|| materialModalData.materialChannel === 'TP_QJJ' || materialModalData.materialChannel === 'TP_EC') && materialModalData.makeCode" :span="12">
            <a-form-item label="创建时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-range-picker v-model:value="materialModalData.uploadTime" style="width: 100%" :placeholder="['开始日期', '结束日期']" separator="到" :format="[dateRangeFormat, dateRangeFormat]" @change="selectYsjjCreateTime" :disabled-date="disabledDate" :disabled="!!materialModalData.makeCode" />
            </a-form-item>
          </a-col>
          <!-- 剪辑和情景剧的脚本类型和编导 -->
          <a-col v-if="(materialModalData.materialChannel === 'QJJ_KB' || materialModalData.materialChannel === 'YSJJ' || materialModalData.materialChannel === 'QJJ'|| materialModalData.materialChannel === 'TP_QJJ' || materialModalData.materialChannel === 'TP_EC') && materialModalData.makeCode" :span="12">
            <a-form-item label="脚本类型" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select v-model:value="materialModalData.mouthPlay" placeholder="请选择脚本类型" v-bind="$filterOptions" allow-clear @change="selectMouthPlay" :disabled="!!materialModalData.makeCode">
                <a-select-option v-for="option in mouthplayOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="materialModalData.makeCode" :span="12">
            <a-form-item label="编导" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select v-model:value="materialModalData.screenwriterName" placeholder="请选择编导" v-bind="$filterOptions" dropdown-class-name="select-options" @change="selectDirector" allow-clear :disabled="!!materialModalData.makeCode">
                <template #notFoundContent>
                  <a-empty :image="simpleImage">
                    <template #description>
                      <span>暂无数据</span>
                    </template>
                  </a-empty>
                </template>
                <a-select-option v-for="option in getAllDirectorList" :key="option.userId" :value="option.userCode" :label="option.userCode">{{ option.userCode }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if=" materialModalData.makeCode && getMouthPlay" :span="12">
            <a-form-item label="摄像" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input v-model:value="shootingName" placeholder="请选择摄像" :disabled="!!materialModalData.makeCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col v-if=" materialModalData.makeCode && getMouthPlay" :span="12">
            <a-form-item label="编导列表" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input v-model:value="screenwriterListStr" placeholder="请选择编导" :disabled="true"></a-input>
            </a-form-item>
<!--            <a-form-item label="编导集合" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" class="actor">
              <a-dropdown style="wdith: 100%">
                <template #overlay>
                  <a-menu>
                    <a-menu-item v-for="item in screenwriterList" :key="item.screenwriterId">{{ item.screenwriterName }}</a-menu-item>
                  </a-menu>
                </template>
                <a-button>
                  编导列表
                  <DownOutlined />
                </a-button>
              </a-dropdown>
            </a-form-item>-->
          </a-col>
          <a-col v-if=" materialModalData.makeCode && getMouthPlay" :span="12">
            <a-form-item label="摄像列表" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input v-model:value="shootingListStr" placeholder="请选择摄像" :disabled="true"></a-input>
            </a-form-item>
<!--            <a-form-item label="摄像集合" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" class="actor">
              <a-dropdown style="wdith: 100%">
                <template #overlay>
                  <a-menu>
                    <a-menu-item v-for="item in shootingList" :key="item.shootingId">{{ item.shootingName }}</a-menu-item>
                  </a-menu>
                </template>
                <a-button>
                  摄像列表
                  <DownOutlined />
                </a-button>
              </a-dropdown>
            </a-form-item>-->
          </a-col>
<!--          参演艺人-->
          <a-col v-if=" materialModalData.makeCode && getMouthPlay" :span="24">
            <a-form-item label="参演艺人" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-textarea v-model:value="actorListStr" placeholder="请选择参演艺人" :disabled="true"></a-textarea>
            </a-form-item>

<!--            <a-form-item label="参演艺人" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" class="actor">
              <a-dropdown style="wdith: 100%">
                <template #overlay>
                 <a-menu>
                    <a-menu-item v-for="item in actorList" :key="item.actorId">{{ item.actorName }}</a-menu-item>
                  </a-menu>
                </template>
                <a-button>
                  参演艺人列表
                  <DownOutlined />
                </a-button>
              </a-dropdown>
            </a-form-item>-->

          </a-col>

          <a-col v-if="operationMode != 'add'" :span="12">
            <!-- 上传人员(编辑条件) -->
            <div v-show="false">{{materialModalData.userId = String(materialModalData.userId)}}</div>
            <a-form-item label="上传人员" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="userId">
              <a-select v-model:value="materialModalData.userId" placeholder="请选择上传人员" v-bind="$filterOptions">
                <a-select-option v-for="option in uploadPersonList" :key="String(option.userId)" :value="String(option.userId)" :label="option.userCode">{{ option.userCode }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="materialModalData.materialChannel === 'CG'" :span="12">
            <a-form-item label="平均成本" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="makeCost">
              <a-input-number style="width: 100%" :min="0" v-model:value="materialModalData.makeCost" placeholder="请输入素材平均成本" />
            </a-form-item>
          </a-col>
          <!-- 标签 -->
          <a-col :span="24">
            <a-form-item label="标签" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <!-- <a-select v-model:value="materialModalData.labelIds" placeholder="请选择标签" show-search :show-arrow="false" :filter-option="false" :not-found-content="null" :options="tagData" @search="handleSearch" :default-active-first-option="false" v-bind="$filterOptions"> </a-select> -->
              <SelectMultiple v-model:value="materialModalData.labelIds" :select-data="materialModalData.labelIds" :select-options="cascaderDataNoTile" placeholder="请选择标签" v-bind="$multipleFilterOptions" select-option-value="id" select-option-label="name" :max-tag-count="8" :disabled="true" />
              <!-- <div class="select-wrapper">
                <div class="cascader-box">
                  <template v-for="key in cascaderDataHierarchy" :key="key">
                    <a-select v-if="key == 1 || selectOptions[key]?.length > 0" v-model:value="cascaders[key]" class="ml10" style="width: 180px" v-bind="$multipleFilterOptions" :allow-clear="false" :placeholder="key + '级标签'" @select="value => handleselectChange(value, selectOptions[key], key)" @deselect="value => deselectChange(value, selectOptions[key])">
                      <a-select-option v-for="(option, index) in selectOptions[key]" :key="index" :value="option.id" :label="option.name">
                        <div class="w100 h100 option-content-wrapper flex">
                          <a-checkbox v-if="!option.end" :checked="option.checked" @click.prevent="handleClickCheckbox(option, key)"></a-checkbox>
                          <div v-if="!option.end" class="option-content pl10" style="flex: 1" @click.stop.prevent="handleClickLabel(option, key)">
                            <p class="option-label" :title="option.name">{{ option.name }}</p>
                          </div>
                          <div v-else class="option-content pl10" style="flex: 1" @click="handleClickCheckbox(option, key)">
                            <p class="option-label" :title="option.name">{{ option.name }}</p>
                          </div>
                        </div>
                      </a-select-option>
                    </a-select>
                  </template>
                </div>
                <div class="show-select-box">
                  <p>已选标签 <a style="font-size: 12px; padding-left: 10px" @click="closeAllTag">清空</a></p>
                  <div>
                    <template v-for="key in cascaderDataHierarchy" :key="key">
                      <a-tag v-for="(item, index) in cascaders[key]" class="mb10" :key="index" closable @close="e => closeTag(e, item, key)">{{ columnMap(cascaderDataNoTile, item, 'id', 'name') }}</a-tag>
                    </template>
                  </div>
                </div>
              </div> -->
              <a-row class="w100 tags-show" :gutter="8">
                <a-col :span="8" v-if="materialModalData.materialChannel === 'QJJ'">
                  <SelectMultiple v-model:value="materialModalData.labelId1" :select-data="materialModalData.labelIds" :select-options="tagTypeList.labelId1" placeholder="人数" v-bind="$multipleFilterOptions" select-option-value="id" select-option-label="name" @change="labelChange" @deselect="labelDeselect" />
                </a-col>
                <a-col :span="8" v-if="materialModalData.materialChannel === 'QJJ'">
                  <SelectMultiple v-model:value="materialModalData.labelId2" :select-data="materialModalData.labelIds" :select-options="tagTypeList.labelId2" placeholder="场地" v-bind="$multipleFilterOptions" select-option-value="id" select-option-label="name" @change="labelChange" @deselect="labelDeselect" />
                </a-col>
                <a-col :span="8" v-if="materialModalData.materialChannel === 'QJJ'">
                  <SelectMultiple v-model:value="materialModalData.labelId3" :select-data="materialModalData.labelIds" :select-options="tagTypeList.labelId3" placeholder="形式" v-bind="$multipleFilterOptions" select-option-value="id" select-option-label="name" @change="labelChange" @deselect="labelDeselect" />
                </a-col>
                <a-col :span="8" v-if="materialModalData.materialChannel === 'QJJ'">
                  <SelectMultiple v-model:value="materialModalData.labelId4" :select-data="materialModalData.labelIds" :select-options="tagTypeList.labelId4" placeholder="情绪" v-bind="$multipleFilterOptions" select-option-value="id" select-option-label="name" @change="labelChange" @deselect="labelDeselect" />
                </a-col>
                <a-col :span="8" v-if="materialModalData.materialChannel === 'YSJJ' || materialModalData.materialChannel === 'EC' || materialModalData.materialChannel === 'HJ'">
                  <SelectMultiple v-model:value="materialModalData.labelId5" :select-data="materialModalData.labelIds" :select-options="tagTypeList.labelId5" placeholder="文本呈现" v-bind="$multipleFilterOptions" select-option-value="id" select-option-label="name" @change="labelChange" @deselect="labelDeselect" />
                </a-col>
                <a-col :span="8" v-if="materialModalData.materialChannel === 'YSJJ' || materialModalData.materialChannel === 'EC' || materialModalData.materialChannel === 'HJ'">
                  <SelectMultiple v-model:value="materialModalData.labelId6" :select-data="materialModalData.labelIds" :select-options="tagTypeList.labelId6" placeholder="画面呈现" v-bind="$multipleFilterOptions" select-option-value="id" select-option-label="name" @change="labelChange" @deselect="labelDeselect" />
                </a-col>
                <a-col :span="8">
                  <SelectMultiple v-model:value="materialModalData.labelId7" :select-data="materialModalData.labelIds" :select-options="tagTypeList.labelId7" placeholder="包装" v-bind="$multipleFilterOptions" select-option-value="id" select-option-label="name" @change="labelChange" @deselect="labelDeselect" />
                </a-col>
                <a-col :span="8">
                  <SelectMultiple v-model:value="materialModalData.labelId8" :select-data="materialModalData.labelIds" :select-options="tagTypeList.labelId8" placeholder="黄金3秒" v-bind="$multipleFilterOptions" select-option-value="id" select-option-label="name" @change="labelChange" @deselect="labelDeselect" />
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
          <!-- 备注 -->
          <a-col :span="24">
            <a-form-item style="margin-top: 5px" label="备注" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-textarea v-model:value="materialModalData.remarkInfo" placeholder="请输入备注" :rows="4" :maxlength="200" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <template v-if="false">
            <a-col :offset="3" v-if="operationMode === 'local'">
              <a-checkbox v-model:checked="materialModalData.similar"> 原素材的二次创作(需要字段！！！！)</a-checkbox>
            </a-col>
            <a-col :offset="3" v-if="operationMode === 'add'">
              <a-checkbox v-model:checked="materialModalData.similar"> 将上传素材作为相似素材(需要字段！！！！)</a-checkbox>
            </a-col>
          </template>
          <a-col :span="24" v-if="operationMode === 'update'">
            <a-form-item style="margin-top: 5px" label="修改原因" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-textarea v-model:value="materialModalData.reason" placeholder="请输入修改原因" :rows="4" :maxlength="200" />
              <span style="color: red">原始素材的数据会被删除，包括相似关系等，请谨慎操作</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-modal>
  <!-- 编辑脚本弹窗 -->
  <a-modal width="650px" :mask-closable="false" v-model:visible="editScriptVisible" title="编辑脚本" :ok-button-props="{ loading: editBtnLoading }" @ok="editScriptClickBtn" @cancel="editScriptClickCancel">
    <a-form ref="editScriptModalData" :rules="editScriptModalRules" :model="editScriptModalData">
      <a-row>
        <a-col :span="12">
          <a-form-item label="脚本类型" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
            <a-select v-model:value="editScriptModalData.mouthPlay" placeholder="请选择脚本类型" show-search allow-clear @change="selectMouthPlay">
              <a-select-option v-for="option in mouthplayOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="makeName">
            <a-input v-model:value="editScriptModalData.makeName" placeholder="请输入脚本名称" allow-clear />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="产品" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="projectCode">
            <a-select v-model:value="editScriptModalData.projectCode" placeholder="请选择产品" show-search allow-clear :filter-option="true" option-filter-prop="label">
              <a-select-option v-for="option in projectListOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="所属组" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="deptId">
            <a-select v-model:value="editScriptModalData.deptId" placeholder="请选择所属组" show-search allow-clear :filter-option="true" option-filter-prop="label" @change="onDeptChange">
              <a-select-option v-for="option in deptOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
            <a-date-picker value-format="YYYY-MM-DD" v-model:value="editScriptModalData.uploadTime" :disabled="true" placeholder="请选择时间" style="width: 100%" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="编导" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="screenwriterId">
            <a-select v-model:value="editScriptModalData.screenwriterId" placeholder="请选择编导" show-search allow-clear :filter-option="true" option-filter-prop="label">
              <a-select-option v-for="option in scenaristOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="editScriptModalData.mouthPlay != 'YSJJ'">
        <a-col :span="12">
          <a-form-item label="参演艺人" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="actorIdArray">
            <a-select v-model:value="editScriptModalData.actorIdArray" placeholder="请选择参演艺人" show-search allow-clear mode="multiple" :filter-option="true" option-filter-prop="label">
              <a-select-option v-for="option in actorOptions" :key="option.value" :value="option.value" :label="option.text">{{ option.text }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="摄像" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="userId">
            <a-select v-model:value="editScriptModalData.userId" placeholder="请选择摄像" show-search allow-clear :filter-option="true" option-filter-prop="label">
              <a-select-option v-for="option in phtographerOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="参演艺人" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="actorIdArray">
            <a-select v-model:value="editScriptModalData.actorIdArray" placeholder="请选择参演艺人" show-search allow-clear mode="multiple" :filter-option="true" option-filter-prop="label">
              <a-select-option v-for="option in actorOptions" :key="option.value" :value="option.value" :label="option.text">{{ option.text }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <EditMaterialTitle v-model:visible="editMaterialTitleVisible" :material-name="materialName" @editTitle="getNewMaterialName" />
  <ChangeMakeCode v-model:visible="changeMakeCodeVisible" :project-code="materialModalData.ofProjectCode" :project-list-options="projectListOptions" :get-script-by-ti-product="getScriptByTiProduct" :mouthplay-options="mouthplayOptions" :get-all-director-list="getAllDirectorList" @changeMakeCode="getChangeMakeCode" />
</template>

<script>
import { Empty } from 'ant-design-vue';
import upload from '@/components/UpDownLoad/upload';
import {
  updateMaterial,
  saveUpdateMaterialData,
  getUserListData,
  getMakeListDataByName,
  getAllDirectorData,
  updateByFileName,
  getMaterialShootingId,
  getMaterialLibraryLabelAnd,
  getMaterialLibraryLabel,
  videoFileToAgent,
} from '@/api/material/material.js';
import { getMaterialSingeCostDataAPI,getActorRelationListData, getActorListDataByDate, getScriptListData, saveScriptData } from '@/api/material/script.js';
import { getUserListDataByJob, getListByJobAndNotDeptId } from '@/api/base/user.js';
import { getDeptListDataByType, getListByDeptTypesData } from '@/api/base/department.js';
import { getProjectInfoById } from '@/api/customer/product.js';

import EditMaterialTitle from './EditMaterialTitle.vue';
import ChangeMakeCode from './ChangeMakeCode.vue';
import SelectMultiple from '@/components/selectMultiple/index.vue';

import { DownOutlined, EditTwoTone, SearchOutlined } from '@ant-design/icons-vue';
import moment from 'moment';
import { mapState } from 'vuex';
import _ from 'lodash';
import { columnMap } from '@/utils/utils.js';

export default {
  name: 'UploadModal',
  components: { upload,/* DownOutlined,*/ EditTwoTone, SearchOutlined, EditMaterialTitle, ChangeMakeCode, SelectMultiple },
  emits: ['afterOk', 'update:visible', 'editScript', 'editMaterialTitle'],
  props: {
    mode: { type: String, default: 'edit' }, //add、edit、update、similar
    type: { type: String, default: 'IMAGE' }, //IMAGE、VIDEO
    projectListOptions: { type: Object, default: () => ({}) },
    modalData: { type: Object, default: () => ({}) }, //会话框中表单数据
    // editScriptData: { type: Object, default: () => ({}) }, //{}, //关联脚本数据
    uploadPersonList: { type: Array, default: () => [] },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      materialProtected:'N',
      protectedPort:'110761960254',
      sitcomTypeList:[
        { label: '口播', value: 'MP' },
        { label: '剧情', value: 'JQ' },
        { label: '街采', value: 'JC' },
        { label: '多人分采', value: 'DRFC' },
        { label: '发布会', value: 'FBH' },
        { label: '访谈', value: 'FT' },
        { label: '原生vlog', value: 'YS_VLOG' },
        { label: '走播', value: 'ZB' },
      ],
      groupNameList:[
        { name: '九筒组', key: '九筒组' },
        { name: '洋气组', key: '洋气组' },
        { name: '大娟组', key: '大娟组' },
        { name: '艾什组', key: '艾什组' },
      ],
      tagsType: [
        { name: '人数', key: 'labelId1' },
        { name: '场地', key: 'labelId2' },
        { name: '形式', key: 'labelId3' },
        { name: '情绪', key: 'labelId4' },
        { name: '文本呈现', key: 'labelId5' },
        { name: '画面呈现', key: 'labelId6' },
        { name: '包装', key: 'labelId7' },
        { name: '黄金3秒', key: 'labelId8' },
      ],
      tagTypeList: {
        labelId1: [],
        labelId2: [],
        labelId3: [],
        labelId4: [],
        labelId5: [],
        labelId6: [],
        labelId7: [],
        labelId8: [],
      },
      profitList: [], // 利益点数组
      columnMap,
      tagData: [], // 标签筛选项
      timer: null, // 定时器
      cascaderDataNoTile: [], // 平铺的标签列表
      cascaderDataHierarchy: 1000, // 最大层级
      cascaderData: [], // 分层标签列表
      cascaderDataSource: [], // 分层标签列表源数据
      selectOptions: {}, // 标签下拉数据
      cascaders: {
        // 标签选中数据
        1: undefined,
      },
      filterDepIdList: [], //筛选的部门数据
      editScriptVisible: false,
      shootingName: null,
      materialName: {},
      editMaterialTitleVisible: false,
      changeMakeCodeVisible: false,
      editScriptModalData: {},
      editBtnLoading: false,
      gettingMakeListData: false,
      editScriptModalRules: {
        //关联脚本格式验证
        makeName: [{ required: true, message: '请选择名称', trigger: 'blur' }],
        projectCode: [{ required: true, message: '请选择产品', trigger: 'blur' }],
        deptId: [{ required: true, message: '请选择所属组', trigger: 'blur', type: 'number' }],
        screenwriterId: [{ required: true, message: '请选择编导', trigger: 'blur' }],
        actorIdArray: [{ required: true, type: 'array', message: '请选择参演艺人', trigger: 'blur' }],
        userId: [{ required: true, message: '请选择摄像', trigger: 'blur' }],
        shootingId: [{ required: true, message: '请选择摄像', trigger: 'blur' }],
      },
      shootingList:[],
      shootingListStr:'',
      screenwriterList:[],//编导列表
      screenwriterListStr:'',//编导列表
      actorList: [], //参演艺人列表
      actorListStr:'',
      deptOptions: [], //获取编辑关联脚本所属组的数据
      filmmakerOptions: [], //制片列表
      scenaristOptions: [], // 编导列表
      phtographerOptions: [], //摄像列表
      phtographerHJOptions: [], //混剪摄像列表
      actorOptions: [], //参演艺人列表
      showDragZone: false, // 拖放区域显示
      allUploaded: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      clearBlob: false, // 清除上传组件的Blob对象
      dateRangeFormat: 'YYYY-MM-DD',
      // 素材类型
      materialChannelOptions: [
        { label: '图片', value: 'NBZZ' },
        { label: '外部采购', value: 'WBCG' },
        { label: '客户提供', value: 'KHTG' },
      ],
      // 脚本类型
      mouthplayOptions: [
        //口播，剧情，街采，多人分采，发布会 ，访谈，原生vlog，走播
        { label: '口播', value: 'MP' },
        { label: '剧情', value: 'JQ' },
        { label: '街采', value: 'JC' },
        { label: '多人分采', value: 'DRFC' },
        { label: '发布会', value: 'FBH' },
        { label: '访谈', value: 'FT' },
        { label: '原生vlog', value: 'YS_VLOG' },
        { label: '走播', value: 'ZB' },

        // { label: '口播', value: 'MP' },
        { label: '耗时口播(废弃)', value: 'NOMP' },
        { label: '情景剧', value: 'QJJNOMP' },
        { label: '剪辑(废弃)', value: 'YSJJ' },
        { label: '采访(废弃)', value: 'CAIFANG' },
      ],
      getAllDirectorList: [], //所有编导数据
      getScriptByTiProduct: [],
      //  表单校验规则
      materialModalRules: {
        materialChannel: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        // protected: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        ofProjectCode: [{ required: true, message: '请选择产品', trigger: 'blur' }],
        useDeptId: [{ required: true, message: '请选择使用部门', trigger: 'blur' }],
        channel: [
          {
            required: true,
            trigger: 'blur',
            type: 'array',
            valvalidator: (rule, value) => {
              if (value instanceof Array && value.length > 0) {
                return Promise.resolve();
              } else {
                return Promise.reject('请选择使用渠道');
              }
            },
          },
        ],
        userId: [
          {
            required: true,
            trigger: 'blur',
            validator: function validatePostName(rule, value) {
              if (!value) return Promise.reject('请选择上传人员');
              else return Promise.resolve();
            },
          },
        ],
        makeCode: [{ required: true, message: '请选择脚本', trigger: 'blur' }],
        makeCost: [
          {
            required: true,
            trigger: 'blur',
            validator: function validateMakeCost(rule, value) {
              if (value == undefined || value == null) {
                return Promise.reject('请输入成本');
              } else if (!/^\d+$/.test(value)) {
                return Promise.reject('请输入非负数！');
              } else {
                return Promise.resolve();
              }
            },
          },
        ],
        materialList: [{ required: true, type: 'array', message: '请上传素材', trigger: 'blur' }],
      },
      getYsjjScriptByTProduct: {}, // 通过剪辑的时间和产品获取脚本
      materialModalData: { materialType: 'IMAGE' },
      userInfoGrades: this.$store.state.oauth.userInfo.grades, //职级 (经理、组长、组员)
      userInfoJobs: this.$store.state.oauth.userInfo.jobs, //岗位 (ADMIN、运营、拍摄、后期)
      btnLoading: false,
    };
  },
  watch: {
    async visible(val) {
      if (val) {
        this.clearBlob = false;
        this.materialModalData = { ...this.modalData, materialList: [], reason: '原素材审批未通过' };
        this.materialModalData.materialType = this.type;
        this.materialModalData.channel = this.materialModalData.channel?.split(',');
        this.allUploaded = true;
        this.getYsjjScriptByTProduct = {}; //获取关联脚本的请求数据
        await this.getMakeListData(); //获取关联脚本列表
        // await this.getListByJobAndNotDeptId(); // 混剪获取摄像
        if (this.materialModalData.materialChannel == 'HJ' || this.materialModalData.materialChannel == 'YSJJ') {
          this.getMaterialShootingId(this.materialModalData.id);
        }
        // 获取关联脚本的详细数据
        if (this.materialModalData.makeCode) {
          this.selectConnectionScript(this.materialModalData.makeCode);
        }
      } else {
        this.clearBlob = true;
      }
    },
    'materialModalData.makeCode'(newVal) {
      if (!newVal) {
        this.materialModalData.screenwriterName = null;
        this.materialModalData.mouthPlay = null;
        this.materialModalData.uploadTime = null;
        this.actorList = [];
        this.shootingName = null;
      }
    },
  },
  computed: {
    ...mapState({
      userId: state => state.oauth.userInfo.userId,
    }),
    //todo 改为image
    uploadFileType() {
      if (this.type === 'IMAGE') {
        return 'image/*';
      } else {
        return 'video/*';
      }
    },
    // 操作方式（add、edit、update、similar）
    operationMode() {
      if (!this.modalData.id) {
        return 'add';
      } else {
        return this.mode;
      }
    },
    // 弹窗title
    modalTitle() {
      let title = '';
      const data = { ...this.materialModalData };
      switch (this.operationMode) {
        case 'edit':
          title = `${data?.fileName} - ${data?.id} - ${data?.postName}`;
          break;
        case 'add':
          title = '添加素材';
          break;
        case 'local':
          title = '添加相似素材-本地';
          break;
        case 'update':
          title = '修改素材';
          break;
      }
      return title;
    },
    getMouthPlay() {
      const makeObj =
        this.getScriptByTiProduct &&
        this.getScriptByTiProduct.find(item => {
          return item.makeCode == this.materialModalData.makeCode;
        });
      return makeObj && makeObj.mouthPlay !== 'YSJJ';
    },
  },
  async mounted() {
    this.getListByJobAndNotDeptId();
    this.getAllDirector(); //获取所有的编导
    this.getDeptListByType(); // 获取使用部门列表
    this.getMaterialLibraryLabelAnd();
    this.getMaterialLibraryLabel();
  },
  methods: {
    /**
     * @description: 标签选择
     * @param {*} value
     * @return {*}
     */
    labelChange(value) {
      this.materialModalData.labelIds = Array.isArray(this.materialModalData.labelIds) && this.materialModalData.labelIds.length > 0 ? this.materialModalData.labelIds : [];
      if (value && value.length > 0) {
        this.materialModalData.labelIds = this.materialModalData.labelIds.concat(value);
        this.materialModalData.labelIds = _.uniq(this.materialModalData.labelIds);
      } else {
        this.materialModalData.labelIds = [];
        for (let i = 1; i < 9; i++) {
          this.materialModalData['labelId' + i] && this.materialModalData['labelId' + i].length > 0 && (this.materialModalData.labelIds = this.materialModalData.labelIds.concat(this.materialModalData['labelId' + i]));
        }
      }
    },
    /**
     * @description: 标签删除
     * @param {*} value
     * @return {*}
     */
    labelDeselect(value) {
      this.materialModalData.labelIds = Array.isArray(this.materialModalData.labelIds) && this.materialModalData.labelIds.length > 0 ? this.materialModalData.labelIds : [];
      value &&
        (this.materialModalData.labelIds = this.materialModalData.labelIds.filter(item => {
          return item != value;
        }));
    },
    /**
     * @description: 获取产品下的利益点
     * @param {*}
     * @return {*}
     */
    async getProfitList(id) {
      let res = await getProjectInfoById(id || '');
      let list = [];
      if (res.code == 0) {
        list = res.data?.label?.split(',') || [];
      }

      list = list?.map(item => {
        return { name: item };
      });
      this.profitList = list;
    },
    /**
     * @description: 获取素材标签列表
     * @param {*}
     * @return {*}
     */
    async getMaterialLibraryLabelAnd() {
      let res = await getMaterialLibraryLabelAnd();
      if (res.code == 0) {
        this.cascaderData = res.data;
        this.cascaderDataSource = _.cloneDeep(res.data);
        this.tagsType.forEach(item => {
          this.tagTypeList[item.key] = res.data.find(data => {
            return data.name == item.name;
          })?.childList;
        });
      }
    },
    /**
     * @description: 获取素材标签列表
     * @param {*}
     * @return {*}
     */
    async getMaterialLibraryLabel() {
      let res = await getMaterialLibraryLabel();
      if (res.code == 0) {
        this.cascaderDataNoTile = res.data;
      }
    },
    /**
     * @description: 上级选中
     * @param {*} value
     * @param {*} arr
     * @return {*}
     */
    handleselectChange(value, arr, key) {
      arr.forEach(item => {
        if (value == item.id) {
          item.checked = true;
          this.selectOptions[key + 1] = item.childList;
        }
      });
      (!this.cascaders[key + 2] || this.cascaders[key + 2].length == 0) && (this.selectOptions[key + 2] = []);
    },
    /**
     * @description: 上级取消选中
     * @param {*}
     * @return {*}
     */
    deselectChange(value, arr) {
      arr.forEach(item => {
        value == item.id && (item.checked = false);
      });
      this.citiesData = [];
    },
    /**
     * @description: 上级选中checkbox
     * @param {*} option
     * @param {*} key
     * @return {*}
     */
    handleClickCheckbox(option, key) {
      option.checked = !option.checked;
      this.selectOptions[key + 1] = option.childList;
      (!this.cascaders[key + 2] || this.cascaders[key + 2].length == 0) && (this.selectOptions[key + 2] = []);
    },
    /**
     * @description: 阻止点击label选中
     * @param {*} option
     * @return {*}
     */
    handleClickLabel(option, key) {
      this.province = this.province?.filter(item => {
        return item !== option.value;
      });
      this.selectOptions[key + 1] = option.childList;
      (!this.cascaders[key + 2] || this.cascaders[key + 2].length == 0) && (this.selectOptions[key + 2] = []);
    },
    /**
     * @description: 关闭tag
     * @param {*} e 关闭的tag
     * @param {*} item 关闭的tag的值
     * @param {*} key tag所在位置
     * @return {*}
     */
    closeTag(e, item, key) {
      this.cascaders[key] =
        this.cascaders[key]?.filter(value => {
          return item != value;
        }) || [];
    },
    /**
     * @description: 清除所有tag
     * @param {*}
     * @return {*}
     */
    closeAllTag() {
      for (let key = 1; key < 100; key++) {
        this.cascaders[key] = [];
        this.selectOptions[key] = [];
        // 预防点击出现下层级但未选中时未被清除下拉框
        this.selectOptions[key + 1] = [];
        this.selectOptions[key + 2] = [];
      }
      this.selectOptions[1] = _.cloneDeep(this.cascaderDataSource);
    },
    /**
     * @description:  标签搜索
     * @param {*} value
     * @return {*}
     */
    handleSearch(value) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        this.cascaderDataNoTile.push({ id: value, name: value });
      }, 300);
    },
    openMakeCodeChange() {
      this.changeMakeCodeVisible = true;
    },
    /** 单独获取混剪摄像id
     * @param {*} id
     * @return {*}
     */
    async getMaterialShootingId(id) {
      let res = await getMaterialShootingId(id);
      if (res.code == 0) {
        this.materialModalData.shootingId = res.data?.[0]?.shootingId + '' || '';
      }
    },
    /** 获取筛选部门数据
     * @param {*}
     * @return {*}
     */
    async getDeptListByType() {
      let res = await getListByDeptTypesData({ deptType: ['OPERATION'] });
      if (res.code === 0) {
        // this.filterDepIdList = res.page?.records;
        this.filterDepIdList = res.data.filter(item => {
          return item.name === "业务二部" || item.name === "业务一部"|| item.name === "业务三部"|| item.name === "业务五部"|| item.name === "业务六部"|| item.name === "洽尔"|| item.name === "客户自运营"
        })
      }
    },
    /** 打开编辑素材名称弹窗
     * @param {*}
     * @return {*}
     */
    editMaterialTitle() {
      this.materialName.oldName = this.materialModalData.fileName;
      this.editMaterialTitleVisible = true;
    },
    /** 获取选中的脚本
     * @param {*} selectKeys
     * @return {*}
     */
    getChangeMakeCode(selectKeys) {
      this.materialModalData.makeCode = selectKeys?.[0] || null;
      // 获取关联脚本的详细数据
      if (this.materialModalData.makeCode) {
        this.selectConnectionScript(this.materialModalData.makeCode);
      }
    },
    /** 获取新的素材名称
     * @param {*} value
     * @return {*}
     */
    async getNewMaterialName(value) {
      if (value) {
        // let materialType = this.materialModalData.fileName?.split('.')?.[1] || '';
        let materialType = this.materialName.oldName?.substring(this.materialName.oldName.lastIndexOf('.')+1) || '';
        if(materialType.length>4){
          materialType = 'mp4';
        }
        let fileName = value.indexOf(materialType) !== -1 ? value : value + '.' + materialType;
        let res = await updateByFileName({ id: this.materialModalData.id, fileName: fileName });
        if (res.code == 0) {
          this.materialModalData.fileName = fileName;
          this.$message.success('素材名称修改成功');
          this.editMaterialTitleVisible = false;
          this.materialName.newName = null;
          this.$emit('editMaterialTitle', fileName);
        } else {
          this.$message.error('素材名称修改失败');
        }
      }
    },
    // 打开关联脚本会话框
    async onEditScriptClick() {
      const makeObj = this.getScriptByTiProduct.find(item => {
        return item.makeCode == this.materialModalData.makeCode;
      });
      let makeId = makeObj && makeObj.id;
      let postData = { page: '1', limit: '10', makeIds: [makeId] };
      let res = await getScriptListData(postData);
      this.editScriptModalData = res.page?.list[0];

      this.editScriptModalData.actorIdArray =
        this.editScriptModalData &&
        this.editScriptModalData.actorInfoList.map(item => {
          return item.actorId.toString();
        });
      this.editScriptVisible = true;
      this.getDeptListEditScript(); // 获取编辑脚本所属组
      this.getScenaristListByJob(); //获取编导
      this.getActorListByDate(); //获取艺人
      this.getUserListByJob(); //获取摄像
    },
    // 获取参演艺人
    async getActorListByDate() {
      let res = await getActorListDataByDate({ date: this.editScriptModalData.uploadTime });
      this.actorOptions =
        res.list &&
        res.list.map(actor => {
          return {
            cost: actor.cost,
            text: actor.actorName,
            value: actor.actorId.toString(),
            key: actor.actorId.toString(),
          };
        });
    },
    // 获取摄像列表
    async getUserListByJob() {
      let res = await getUserListDataByJob({ job: 'PHOTOGRAPHER', deptId: this.editScriptModalData.deptId });
      this.phtographerOptions =
        res.list &&
        res.list.map(ph => {
          return {
            label: ph.userCode,
            value: ph.userId.toString(),
          };
        });
    },
    // 获取混剪摄像列表
    async getListByJobAndNotDeptId() {
      let res = await getListByJobAndNotDeptId({ job: 'PHOTOGRAPHER' });
      this.phtographerHJOptions =
        res.list &&
        res.list.map(ph => {
          return {
            userCode: ph.userCode,
            userId: ph.userId.toString(),
          };
        });
    },
    // 获取制片列表
    async getFilmmakerListByJob() {
      let res = await getUserListData({ jobs: 'FILEMAKER'});
      this.filmmakerOptions =
        res.page &&
        res.page.list?.map(ph => {
          return {
            label: ph.userCode,
            value: ph.userId.toString(),
          };
        });
    },
    // 获取编导列表
    async getScenaristListByJob() {
      let res = await getUserListDataByJob({ job: 'SCENARIST', deptId: this.editScriptModalData.deptId });
      this.scenaristOptions =
        res.list &&
        res.list.map(ph => {
          return {
            label: ph.userCode,
            value: ph.userId.toString(),
          };
        });
    },
    // 获取编辑脚本所属组
    async getDeptListEditScript() {
      let res = await getDeptListDataByType({ deptType: 'VIDEO' });
      this.deptOptions =
        res.list &&
        res.list.map(d => {
          return { label: d.name, value: d.deptId };
        });
    },
    // 改变编辑脚本的部门
    onDeptChange() {
      this.editScriptModalData.screenwriterId = null;
      this.editScriptModalData.userId = null;
      this.getScenaristListByJob();
      this.getUserListByJob();
    },
    // 确认编辑关联脚本
    async editScriptClickBtn() {
      await this.$refs.editScriptModalData.validate();
      let objParams = {
        ...this.editScriptModalData,
      };
      this.editBtnLoading = true;
      objParams.shootingId = objParams.userId;
      objParams.id = this.editScriptModalData.makeId;
      objParams.uploadTime = this.editScriptModalData.uploadTime.split(' ')[0];
      let res = await saveScriptData(objParams);
      this.editBtnLoading = false;
      if (res.code === 0) {
        this.$message.success('编辑成功!');
        this.materialModalData.makeCode = null;
        this.editScriptVisible = false;
      }
    },
    // 取消编辑关联脚本
    editScriptClickCancel() {
      this.editScriptModalData = {};
      this.editBtnLoading = false;
    },
    // 上传完成
    uploadComplete(res) {
      if (!this.materialModalData.materialList) {
        this.materialModalData.materialList = [];
      }

      res.forEach(item => {
        if (this.materialModalData.materialList.some(m => m.md5 == item.md5) || item.exist) return;
        this.materialModalData.materialList.push({
          etag: item.etag,
          url: item.url,
          md5: item.md5,
          thumb: item.thumb,
          fileName: item.fileName,
          resolutionLong: item.width || 0,
          resolutionHeight: item.height || 0,
          direction: item.width > item.height ? 'HORIZONTAL' : 'VERTICAL',
          file: item.file,
        });
      });
      // this.$refs.materialModal.validate();
    },
    // 移除文件
    removeFile(md5) {
      this.materialModalData.materialList = this.materialModalData.materialList.filter(m => m.md5 != md5);
    },
    //获取所有的编导数据
    async getAllDirector() {
      let params = {
        job: 'SCENARIST',
      };
      let res = await getAllDirectorData(params);
      if (res.code === 0) {
        this.getAllDirectorList = res.list;
      }
    },
    // 获取关联脚本列表
    async getMakeListData() {
      this.gettingMakeListData = true;
      let postData = { ...this.getYsjjScriptByTProduct };
      postData.projectCode = this.materialModalData.ofProjectCode;
      if (!postData.startUploadTime) {
        delete postData.startUploadTime;
        delete postData.endUploadTime;
      }
      if(!postData.makeName){
        postData.makeName = '';
      }
      let res = await getMakeListDataByName(postData);
      if (res.code === 0) {
        this.getScriptByTiProduct = res.list;
      }
      this.gettingMakeListData = false;
    },
    // 改变添加素材的素材类型
    async changeChannel(value) {
      let materialList = this.materialModalData.materialList;
      let materialId = this.materialModalData.id;
      this.materialModalData = {
        materialChannel: this.materialModalData.materialChannel,
        materialList: materialList,
        id: materialId,
        shootingIds: this.modalData.shootingIds,
        screenwriterIds: this.modalData.screenwriterIds,
      };
      this.getYsjjScriptByTProduct = {};
      this.getMakeListData();
      if (this.materialModalData.materialChannel == 'HJ' || this.materialModalData.materialChannel == 'YSJJ') {
        // this.getListByJobAndNotDeptId();
      }
      if (this.materialModalData.materialChannel == 'TP_HQ_PY') {
        this.getFilmmakerListByJob();
      }
    },
    // 剪辑根据时间选择脚本
    async selectYsjjCreateTime(date, dateString) {
      this.getYsjjScriptByTProduct.startUploadTime = dateString[0];
      this.getYsjjScriptByTProduct.endUploadTime = dateString[1];
      this.materialModalData.startUploadTime = dateString[0];
      this.materialModalData.endUploadTime = dateString[1];
      this.getMakeListData();
    },
    // 剪辑根据产品选择脚本
    async selectYsjjProduct(value) {
      this.getYsjjScriptByTProduct.projectCode = value;
      this.getMakeListData();
    },
    // 根据口播选择脚本
    async selectMouthPlay(value) {
      this.getYsjjScriptByTProduct.mouthPlay = value;
      this.materialModalData.makeCode = null;
      this.getMakeListData();
    },
    // 根据编导获取脚本
    async selectDirector(value) {
      this.getYsjjScriptByTProduct.screenwriterName = value;
      this.getMakeListData();
    },
    // 选择关联产品
    changeProduct(value) {
      this.materialModalData.makeCode = null;
      if (!value) {
        this.getYsjjScriptByTProduct.projectCode = '';
        this.getMakeListData();
      } else {
        this.getProfitList(this.materialModalData.ofProjectCode);
      }
    },
    // 选择关联脚本后获取摄影
    async selectConnectionScript(value) {
      // this.materialModalData.screenwriterId = null;
      // this.materialModalData.shootingId = null;
        console.error("===========================// 选择关联脚本后获取摄影========================")
      let obj = this.getScriptByTiProduct.filter(item => {
        return item.makeCode == value;
      });
      if (obj && obj[0]) {
        this.editScriptModalData.userId = obj[0].shootingId;
        this.shootingName = obj[0].shootingName;
        this.makeId = obj[0].id;
        this.selectActor();
        this.getMaterialSingeCostData();

        // 获取关联脚本的id
        let scriptObj = this.getScriptByTiProduct.find(item => {
          return item.makeCode == value;
        });
        let params = {
          makeIds: [scriptObj.id],
        };
        let res = await getScriptListData(params);
        if (res.code === 0) {
          this.editScriptModalData = res.page.list[0];
          this.editScriptModalData.actorIdArray = [];
          res.page.list[0].actorInfoList.forEach(item => {
            this.editScriptModalData.actorIdArray.push(item.actorId.toString());
          });
          if (res.page.list[0]) {
            this.materialModalData.screenwriterName = res.page.list[0].screenwriterCode;
            this.materialModalData.mouthPlay = res.page.list[0].mouthPlay;
            this.materialModalData.uploadTime = [moment(res.page.list[0].uploadTime), moment(res.page.list[0].uploadTime)];
          }
        }
      }
    },
    // 选择关联脚本后获取参演一人
    async selectActor() {
      let res = await getActorRelationListData(this.makeId);
      if (res.code === 0) {
        this.actorList = res.list;
        this.actorListStr = res.list?.map(e=>{
         return  e.actorName
        }).join(',');
      }
    },
    // 获取脚本信息  编导、摄像集合
    async getMaterialSingeCostData() {
      let res = await getMaterialSingeCostDataAPI({"makeIds":[this.makeId]});

      if (res.code === 0 && res.page.list[0]) {
        this.screenwriterList = res.page.list[0].screenwriterBOList?.map(ph => {
          return {
            screenwriter_name: ph.screenwriter_name,
            screenwriter_id: String(ph.screenwriter_id),
          };
        });
        this.screenwriterListStr = res.page.list[0].screenwriterBOList?.map(ph => {
          return ph.screenwriter_name
        }).join(',');

        this.shootingList = res.page.list[0].shootingBOList?.map(ph => {
          return {
            shooting_name: ph.shooting_name,
            shooting_id: ph.shooting_id.toString(),
          };
        });
        this.shootingListStr = res.page.list[0].shootingBOList?.map(ph => {
          return ph.shooting_name
        }).join(',');
      }
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    processUndefinedVal(postData,data){
      Object.keys(postData).forEach(key => (postData[key] = data[key]));
      // 遍历postData的所有键
      Object.keys(postData).forEach(key => {
        // 如果某个键的值是undefined, 则删除该键
        if (postData[key] === "undefined" || !postData[key]) {
          delete postData[key];
        }
      });
    },
    processDelUndefinedVal(postData){
      // 遍历postData的所有键
      Object.keys(postData).forEach(key => {
        // 如果某个键的值是undefined, 则删除该键
        if (postData[key] === "undefined" || !postData[key]) {
          delete postData[key];
        }
      });
    },
    // 点击确定
    async handleOK() {

      if (!this.allUploaded) {
        this.$message.warn('请等待所有文件上传完毕后再保存');
        return;
      }
      let data = { url: this.materialModalData.fileName, ...this.materialModalData, materialType: this.type ,materialProtected: this.materialProtected};
      delete data.uploadTime;
      delete data.startUploadTime;
      delete data.endUploadTime;
      const postData = {
        id: undefined,
        materialChannel: undefined,
        userId: undefined,
        makeCost: undefined,
        makeCode: undefined,
        ofProjectCode: undefined,
        mouthPlay: undefined,
        postName: undefined,
        remarkInfo: undefined,
        reason: undefined,
        resolutionHeight: undefined,
        resolutionLong: undefined,
        shootingId: undefined,
        shootingIds: undefined,
        producerId: undefined,
        screenwriterId: undefined,
        screenwriterIds: undefined,
        useDeptId: undefined,
        channel: undefined,
        sitcomType:undefined,
      };
      /*Object.keys(postData).forEach(key => (postData[key] = data[key]));
      // 遍历postData的所有键
      Object.keys(postData).forEach(key => {
        // 如果某个键的值是undefined, 则删除该键
        if (postData[key] === "undefined" || !postData[key]) {
          delete postData[key];
        }
      });*/
      this.processUndefinedVal(postData,data);
      console.log(postData);
      if (this.operationMode === 'edit') {
        delete postData.reason;
        this.materialModalRules.materialList = [{ required: false, trigger: blur }];
        postData.md5 = this.materialModalData.md5;
      } else {
        this.materialModalRules.materialList = [{ required: true, type: 'array', message: '请上传素材', trigger: 'blur' }];
        postData.md5 = this.materialModalData.materialList[0].md5;
      }
      let res = null;
      let labelValues = '';
      data.label?.forEach(item => {
        labelValues += item.value + ',';
      });
      data.label = labelValues.replace(/.$/, '');
      for (let i = 1; i < 9; i++) {
        data['labelId' + i] && delete data['labelId' + i];
      }
      // data.labelIds = [];
      // Object.keys(this.cascaders)?.forEach(key => {
      //   this.cascaders[key] && (Array.isArray(this.cascaders[key]) ? (data.labelIds = data.labelIds.concat(this.cascaders[key])) : data.labelIds.push(this.cascaders[key]));
      // });
      this.processUndefinedVal(postData,data);
      await this.$refs.materialModal.validate().then(async () => {
        this.btnLoading = true;
        if (this.operationMode === 'update' || this.operationMode === 'edit') {
          const param = { ...postData, ...data.materialList[0], label: data.label, labelIds: data.labelIds };
          param.channel = param.channel.join(',');
          if (this.operationMode!='edit' && this.type=='VIDEO'){
            param.materialProtected = this.materialProtected;
            param.protectedPort = this.protectedPort;
          }
          this.processDelUndefinedVal(param);
          res = await updateMaterial(param);
        } else {
          delete data.reason;
          data.channel = data.channel.join(',');
          if (this.type=='VIDEO'){
            data.materialProtected = this.materialProtected;
            data.protectedPort = this.protectedPort;
          }
          this.processDelUndefinedVal(data);
          res = await saveUpdateMaterialData(data);
        }
      });
      if (res?.code === 0) {
        /*if (this.materialProtected == 'Y' && this.operationMode!='edit' && this.type=='VIDEO') {
          this.materialModalData.materialList.forEach(item=>{
            console.error("-------------------------上传方舟--------------------")
            let videoToAgentResult = videoFileToAgent(item.fileName,item.md5,item.file,this.protectedPort,this.materialModalData.ofProjectCode,false);
          })
        }*/


        const obj = { event: 'uploadModalClose', args: { mode: this.mode } };
        this.$store.commit('SET_GLOBALEVENT', obj);
        this.$emit('afterOk', { refresh: true },true,true);
        if (postData.id) {
          this.$message.success('更改成功');
        } else {
          this.$message.success('新增成功');
        }
        this.$emit('update:visible', false);
        this.btnLoading = false;
      }

      this.btnLoading = false;
    },
    // 点击取消
    handleCancel() {
      this.$refs.materialModal.resetFields();
      this.clearBlob = true;
      // this.closeAllTag();
      this.$emit('update:visible', false);
    },
  },
};
</script>

<style  lang="less">
.tags-show {
  .ant-select-selector {
    flex-wrap: nowrap;
    .ant-select-selection-item {
      max-width: 65%;
    }
  }
}
.materialHandleModal {
  .select-wrapper {
    display: flex;
    justify-content: space-between;
    .cascader-box {
      width: 54%;
      padding: 8px;
      overflow: auto;
      border: 1px solid #d9d9d9;
    }
    .show-select-box {
      width: 44%;
      padding: 8px;
      margin-left: 5%;
      border: 1px solid #d9d9d9;
      & > div {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }
  .material_title_edit {
    display: none;
    cursor: pointer;
  }
  .ant-modal-header:hover .material_title_edit {
    display: inline-block;
  }

  .ant-modal-header {
    padding: 16px 20px;
  }
  .ant-modal-body {
    padding: 13px;
    overflow: auto;
    .ant-form-item {
      margin-bottom: 0.625rem;
    }
  }
  .ant-form {
    min-width: 500px;
    .ant-form-item {
      margin-bottom: 5px !important;
      .ant-form-item-label {
        line-height: 40px;
      }
    }
  }
  .custom_upload {
    .header {
      .btn-container {
        margin-left: 70px !important;
      }
    }
    .file-list {
      margin-top: 0;
    }
  }
}
</style>